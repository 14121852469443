import { useContext, useState, useEffect } from "react";
import RentalSummary from "../layout/blocks/RentalSummary";
import { Link, useNavigate } from "react-router-dom";
import api from "../../service/race_api";
import Moment from "moment";
import {
  CartContext,
  FilterContext,
  AdditionalChargesContext,
} from "../../components/context/MainProviders";
import Breadcrumb from "../layout/blocks/Breadcrumb";

const Review = () => {
  const filter = useContext(FilterContext);
  const cart = useContext(CartContext);
  const additionalCharges = useContext(AdditionalChargesContext);
  const [carId, setCarId] = useState(0);
  const [car, setCar] = useState({});
  const [features, setFeatures] = useState([]);
  const [diffDays, setDiffDays] = useState(0);
  const [locations, setLocations] = useState([]);
  const [billingAddress, setBillingAddress] = useState(
    sessionStorage.getItem("billing_address") !== null
      ? JSON.parse(sessionStorage.getItem("billing_address"))
      : {
          first_name: "",
          last_name: "",
          email: "",
          phone: "",
          city: "",
          address: "",
          zip_code: "",
          country: "MU",
          birthday: "",
          hotel_room: "",
          prev_emai: "",
        }
  );
  const [driversLicense, setDriversLicense] = useState(
    sessionStorage.getItem("drivers_license") !== null
      ? JSON.parse(sessionStorage.getItem("drivers_license"))
      : {
          dl_number: "",
          expiration_date: "",
          id_file: "",
          file: "",
        }
  );
  const calculateDiffDays = () => {
    let calculateDiffDays = 0;
    if (filter.dateReturn !== null && filter.datePickup !== null) {
      let diffTime = filter.dateReturn.getTime() - filter.datePickup.getTime();
      calculateDiffDays = diffTime / (1000 * 3600 * 24);
      setDiffDays(calculateDiffDays);
    }
    return calculateDiffDays;
  };
  const closeFieldset = (id) => {
    let fieldset = document.getElementById(id);
    if (fieldset.classList.contains("hidden") === false) {
      fieldset.classList.add("hidden");
    } else {
      fieldset.classList.remove("hidden");
    }
  };
  const initCarInformation = async (carId) => {
    const carInfo = await api.get(
      process.env.REACT_APP_API_FLEETS_VEHICLE.replace("[id]", carId)
    );
    setCar(carInfo.data.data);
    const id_class = carInfo.data.data.vehicle_class.id;
    const carClass = await api.get(
      process.env.REACT_APP_API_VEHICLE_CLASS.replace("[id]", id_class)
    );
    setFeatures(carClass.data.fleets_vehicle_class.features);
  };
  const verifyAdditionalChargeSelected = (id_service) => {
    let found = false;
    if (additionalCharges.selectedItems !== null) {
      additionalCharges.selectedItems.forEach((selectedAdditionalCharge) => {
        if (
          selectedAdditionalCharge.id_service === id_service &&
          selectedAdditionalCharge.value !== "0" &&
          selectedAdditionalCharge.value !== false
        ) {
          found = true;
        }
      });
    }
    return found;
  };
  const getServiceAmount = (price, id_service, diff_days) => {
    let amount = 0;
    if (additionalCharges.selectedItems !== null) {
      additionalCharges.selectedItems.forEach((category) => {
        category.list.forEach((item) => {
          if (
            item.id_service === id_service &&
            item.value !== "0" &&
            item.value !== false
          ) {
            if (item.value === true) {
              amount = price;
            } else {
              amount = price * item.value;
            }
          }
        });
      });
    }
    return Math.floor(amount);
  };
  const acceptTermsAndConditions = (value) => {
    cart.changeAcceptedTermsAndConditions(value);
    if (value) document.getElementById("modal").classList.add("hidden");
  };
  const cartContinue = () => {
    const summary = document.getElementById("rental-summary");
    summary
      .querySelector("div.summary-actions button.confirm-reservation")
      .click();
  };
  const navigate = useNavigate();
  useEffect(() => {
    if (cart.isEmpty) {
      navigate("/cars");
    } else {
      cart.changeCartStep("review");
      calculateDiffDays();
      setCarId(sessionStorage.getItem("car_id"));
      initCarInformation(sessionStorage.getItem("car_id"));
      //fill out the saved locations in local storage
      api.get(process.env.REACT_APP_API_FLEETS_LOCATIONS).then((res) => {
        setLocations(res.data.fleets_locations);
      });
    }
  }, []);
  return (
    <section className="content max-w-xl mx-auto overflow-hidden md:max-w-2xl lg:max-w-4xl xl:max-w-6xl p-4">
      <Breadcrumb />
      <h2 className="text-red-600 text-2xl text-center mb-4">
        <span className="text-black text-lg">Our</span> <br />
        Cars
      </h2>
      <div id="review" className="flex flex-col lg:flex-row">
        <div className="mb-2 sm:mb-10 w-full lg:w-3/4 flex flex-wrap lg:pr-6">
          <fieldset className="w-full mb-4">
            <legend
              className="w-full inline-block mb-3 p-2 border-b border-neutral-300"
              onClick={(e) => closeFieldset("fielset-content-date-time")}
            >
              <h2 className="text-md text-red-600 w-full">Date & Time</h2>
            </legend>
            <div
              id="fielset-content-date-time"
              className="fielset-content w-full flex flex-wrap"
            >
              <div className="read-only-field w-full sm:w-1/4 p-2 flex flex-col mb-1">
                <label className="w-full mb-1 text-xs font-bold">
                  Pickup date
                </label>
                <p className="text-xs text-neutral-500">
                  {`${filter.datePickup.getDate()}/${
                    filter.datePickup.getMonth() + 1
                  }/${filter.datePickup.getFullYear()}`}
                </p>
              </div>
              <div className="read-only-field w-full sm:w-1/4 p-2 flex flex-col mb-1">
                <label className="w-full mb-1 text-xs font-bold">
                  Pickup time
                </label>
                <p className="text-xs text-neutral-500">
                  {Moment(filter.datePickup).format("h:mm a")}
                </p>
              </div>
              <div className="read-only-field w-full sm:w-1/4 p-2 flex flex-col mb-1">
                <label className="w-full mb-1 text-xs font-bold">
                  Return date
                </label>
                <p className="text-xs text-neutral-500">
                  {`${filter.dateReturn.getDate()}/${
                    filter.dateReturn.getMonth() + 1
                  }/${filter.dateReturn.getFullYear()}`}
                </p>
              </div>
              <div className="read-only-field w-full sm:w-1/4 p-2 flex flex-col mb-1">
                <label className="w-full mb-1 text-xs font-bold">
                  Return time
                </label>
                <p className="text-xs text-neutral-500">
                  {Moment(filter.dateReturn).format("h:mm a")}
                </p>
              </div>
              <div className="read-only-field w-full sm:w-1/4 p-2 flex flex-col mb-1">
                <label className="w-full mb-1 text-xs font-bold">
                  Pickup Location
                </label>
                <p className="text-xs text-neutral-500">
                  {locations.map((location) =>
                    location.id == filter.from ? location.name : ""
                  )}
                </p>
              </div>
              <div className="read-only-field w-full sm:w-1/4 p-2 flex flex-col mb-1">
                <label className="w-full mb-1 text-xs font-bold">
                  Return location
                </label>
                <p className="text-xs text-neutral-500">
                  {locations.map((location) =>
                    location.id == filter.to ? location.name : ""
                  )}
                </p>
              </div>
            </div>
          </fieldset>
          <fieldset className="w-full mb-4">
            <legend
              className="w-full inline-block mb-3 p-2 border-b border-neutral-300"
              onClick={(e) => closeFieldset("fielset-content-selected-vehicle")}
            >
              <h2 className="text-md text-red-600 w-full">
                Selected Vehicle Class
              </h2>
            </legend>
            <div
              id="fielset-content-selected-vehicle"
              className="w-full fieldset-content flex flex-wrap border-b border-neutral-200 pb-4"
            >
              <div className="read-only-field w-full sm:w-1/4 flex flex-col p-2">
                <img
                  src={
                    Object.keys(car).length !== 0
                      ? car.vehicle_class.public_image_link
                      : ""
                  }
                  alt=""
                  className="w-full"
                />
              </div>
              <div className="read-only-field w-full sm:w-2/4 flex flex-col p-2">
                <h3 className="text-lg">
                  {Object.keys(car).length !== 0 ? car.label : ""}
                </h3>
                <p className="text-xs text-neutral-500">
                  {car.long_description}
                </p>
                {features.length > 0 ? (
                  <ul className="flex flex-wrap p-0 list-none mt-4 text-xs">
                    {features.map((feature) => (
                      <li
                        key={feature.id}
                        className="w-full sm:w-2/4 xl:w-2/4 text-neutral-400 font-bold py-1 flex flex-wrap"
                      >
                        {/* <i
                          className={feature.icon + " mr-2 text-red-600 w-3"}
                        ></i> */}
                        <p>{feature.label}</p>
                      </li>
                    ))}
                  </ul>
                ) : null}
              </div>
              <div className="read-only-field w-full sm:w-1/4 flex flex-col p-2">
                <div className="bg-neutral-200 p-4 border border-neutral-300">
                  <ul className="text-right text-neutral-600">
                    <li className="text-md">
                      {process.env.REACT_APP_CURRENCY}
                      {Object.keys(car).length > 0
                        ? car.vehicle_class.active_rates.length > 0
                          ? Math.floor(
                              car.vehicle_class.active_rates[0].daily_rate
                            )
                          : 0
                        : 0}
                      /<span className="text-xs">Day</span>
                    </li>
                    <li className="text-xs">{`${
                      Object.keys(car).length > 0
                        ? car.vehicle_class.active_rates.length > 0
                          ? Math.floor(
                              car.vehicle_class.active_rates[0].daily_rate
                            )
                          : 0
                        : 0
                    } total for 1 Day`}</li>
                    <li className="text-xs">Includes taxes & insurance(s)</li>
                  </ul>
                </div>
              </div>
            </div>
          </fieldset>
          <fieldset className="w-full mb-4">
            <legend
              className="w-full inline-block mb-3 p-2 border-b border-neutral-300"
              onClick={(e) =>
                closeFieldset("fielset-content-additional-services")
              }
            >
              <h2 className="text-md text-red-600 w-full">
                Additional Charges
              </h2>
            </legend>
            <div
              className="field flex flex-col w-full mt-2 mb-6"
              id="fielset-content-additional-services"
            >
              <ul className="flex flex-col p-0 list-none">
                {additionalCharges.selectedItems.length > 0
                  ? additionalCharges.selectedItems.map((category, index) => (
                      <li
                        key={"category-" + index}
                        className="py-4 border-b border-neutral-200"
                      >
                        <h2 className="text-normal md:text-xl w-full">
                          {category.category_name}
                        </h2>
                        {category.list.map((service, index2) => (
                          <div
                            className="flex flex-row md:flex-row justify-between"
                            key={"service-" + index2}
                          >
                            <div className="p-2 w-2/3 md:w-8/12">
                              <h3 className="text-normal md:text-lg mb-4 font-light flex">
                                {/* <i
                                  className={
                                    service.icon + " mt-[2px] mr-2 text-red-600"
                                  }
                                ></i> */}
                                {service.name}
                              </h3>
                              {/* <p className="text-xs text-neutral-500 font-light">
                              {service.description}
                            </p>
                            <br /> */}
                              {/* {service.hasOwnProperty("notice") ? (
                              <p className="text-xs text-red-500 font-normal">
                                {service.notice}
                              </p>
                            ) : null} */}
                            </div>
                            <div className="text-xs bg-neutral-100 py-4 px-1 border border-neutral-200 rounded h-12 w-1/3 md:w-3/12 mt-3 text-neutral-700 font-bold">
                              <span className="font-normal">Yes </span>
                              {process.env.REACT_APP_CURRENCY}
                              {getServiceAmount(
                                service.amount,
                                service.id_service,
                                diffDays
                              ) + "/Day"}
                            </div>
                          </div>
                        ))}
                      </li>
                    ))
                  : null}
              </ul>
            </div>
          </fieldset>
          <fieldset className="w-full">
            <legend
              className="w-full inline-block mb-3 p-2 border-b border-neutral-300"
              onClick={(e) =>
                closeFieldset("fielset-content-customer-information")
              }
            >
              <h2 className="text-md text-red-600 w-full">
                Customer Information
              </h2>
            </legend>
            <div
              id="fielset-content-customer-information"
              className="fielset-content w-full flex flex-wrap"
            >
              <div className="read-only-field w-full sm:w-2/4 p-2 flex flex-col mb-1">
                <label
                  htmlFor="first-name"
                  className="w-full mb-1 text-xs font-bold"
                >
                  First Name
                </label>
                <p className="text-xs text-neutral-600">
                  {billingAddress.first_name}
                </p>
              </div>
              <div className="read-only-field w-full sm:w-2/4 p-2 flex flex-col mb-1">
                <label
                  htmlFor="last-name"
                  className="w-full mb-1 text-xs font-bold"
                >
                  Last Name
                </label>
                <p className="text-xs text-neutral-600">
                  {billingAddress.last_name}
                </p>
              </div>
              <div className="read-only-field w-full sm:w-2/4 p-2 flex flex-col mb-1">
                <label
                  htmlFor="email"
                  className="w-full mb-1 text-xs font-bold"
                >
                  E-mail Address
                </label>
                <p className="text-xs text-neutral-600">
                  {billingAddress.email}
                </p>
              </div>
              <div className="read-only-field w-full sm:w-2/4 p-2 flex flex-col mb-1">
                <label
                  htmlFor="phone"
                  className="w-full mb-1 text-xs font-bold"
                >
                  Phone Number
                </label>
                <p className="text-xs text-neutral-600">
                  {billingAddress.phone}
                </p>
              </div>
              <div className="read-only-field w-full sm:w-2/4 p-2 flex flex-col mb-1">
                <label htmlFor="city" className="w-full mb-1 text-xs font-bold">
                  City
                </label>
                <p className="text-xs text-neutral-600">
                  {billingAddress.city}
                </p>
              </div>
              <div className="read-only-field w-full sm:w-2/4 p-2 flex flex-col mb-1">
                <label
                  htmlFor="address"
                  className="w-full mb-1 text-xs font-bold"
                >
                  Address
                </label>
                <p className="text-xs text-neutral-600">
                  {billingAddress.address}
                </p>
              </div>
              <div className="read-only-field w-full sm:w-2/4 p-2 flex flex-col mb-1">
                <label htmlFor="zip" className="w-full mb-1 text-xs font-bold">
                  Zip code
                </label>
                <p className="text-xs text-neutral-600">
                  {billingAddress.zip_code}
                </p>
              </div>
              <div className="read-only-field w-full sm:w-2/4 p-2 flex flex-col mb-1">
                <label htmlFor="zip" className="w-full mb-1 text-xs font-bold">
                  Country
                </label>
                <p className="text-xs text-neutral-600">
                  {billingAddress.country}
                </p>
              </div>
              <div className="read-only-field w-full sm:w-2/4 p-2 flex flex-col mb-1">
                <label htmlFor="zip" className="w-full mb-1 text-xs font-bold">
                  Birthday
                </label>
                <p className="text-xs text-neutral-600">
                  {Moment(billingAddress.birthday).format("DD/MM/YYYY")}
                </p>
              </div>
            </div>
          </fieldset>
          <fieldset className="w-full">
            <legend
              className="w-full inline-block mb-3 p-2 border-b border-neutral-300"
              onClick={(e) => closeFieldset("fielset-content-drivers-license")}
            >
              <h2 className="text-md text-red-600 w-full">Driver's License</h2>
            </legend>
            <div
              id="fielset-content-drivers-license"
              className="w-full fieldset-content flex flex-wrap"
            >
              <div className="read-only-field w-full sm:w-1/3 flex flex-col p-2">
                <label
                  htmlFor="dl-number"
                  className="w-full text-xs mb-2 font-bold"
                >
                  DL Number
                </label>
                <p className="text-xs text-neutral-600">
                  {driversLicense.dl_number}
                </p>
              </div>
              <div className="read-only-field w-full sm:w-1/3 flex flex-col p-2">
                <label
                  htmlFor="dl-number"
                  className="w-fual text-xs mb-2 font-bold"
                >
                  Expiration Date
                </label>
                <p className="text-xs text-neutral-600">
                  {Moment(driversLicense.expiration_date).format("DD/MM/YYYY")}
                </p>
              </div>
              <div className="read-only-field w-full sm:w-1/3 flex flex-col p-2">
                <label
                  htmlFor="file-upload"
                  className="w-full text-xs mb-2 font-bold"
                >
                  File Upload
                </label>
                <p className="text-xs text-neutral-600">
                  {Object.keys(driversLicense).length > 0 && (
                    <img className="w-[130px]" src={driversLicense.file} />
                  )}
                </p>
              </div>
            </div>
          </fieldset>
          <div className="actions flex flex-wrap w-full mt-4 mb-10">
            <div className="term-conditions flex mr-4 w-full sm:w-3/5">
              <input
                type="checkbox"
                className="mt-2"
                name="terms-and-conditions"
                id="terms-and-conditions"
                onChange={(e) => acceptTermsAndConditions(e.target.checked)}
                checked={cart.acceptedTermsAndConditions}
              />
              <label
                className="text-xs text-neutral-600 leading-8 ml-2"
                htmlFor="terms-and-conditions"
              >
                By clicking here, I confirm that I accept the{" "}
                <Link
                  className="text-blue-500"
                  to="/terms-and-conditions"
                  target="_blank"
                >
                  Terms & Conditions
                </Link>
              </label>
            </div>
            <button
              className="text-white bg-neutral-500 py-1 px-2 text-md border border-neutral-400 mr-2 w-1/4 sm:w-[100px] mb-2 sm:mb-0"
              onClick={() => navigate("/billing")}
            >
              Back
            </button>
            <button
              className="text-white bg-red-600 py-1 px-2 text-md"
              onClick={cartContinue}
            >
              Continue
            </button>
          </div>
        </div>
        {!cart.isEmpty ? <RentalSummary step="placeorder" /> : null}
      </div>
    </section>
  );
};

export default Review;
