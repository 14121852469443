import axios from "axios";

export default axios.create({
  baseURL: process.env.REACT_APP_REST_API_PAYMENT,
  PORT: 443,
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    Authorization: process.env.REACT_APP_REST_API_PAYMENT_AUTH_PRODUCTION,
  },
});
