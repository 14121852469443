import { useState, useEffect, useContext } from "react";
import Breadcrumb from "../layout/blocks/Breadcrumb";
import paymentApi from "../../service/paymentApi";
import { Link } from "react-router-dom";
import api from "../../service/api";
import race_api from "../../service/race_api";
import {
  CartContext,
  AdditionalChargesContext,
} from "../context/MainProviders.js";
import Moment from "moment";
const Response = () => {
  const cart = useContext(CartContext);
  const additionalCharges = useContext(AdditionalChargesContext);
  const [paymentSuccessful, setPaymentSuccessful] = useState(false);
  const [idCheckout, setIdCheckout] = useState(0);
  const [paymentSuccessfulMessage, setPaymentSuccessfulMessage] = useState("");
  const [paymentPending, setPaymentPending] = useState(false);
  const [paymentPendingMessage, setPaymentPendingMessage] = useState("");
  const [paymentError, setPaymentError] = useState(false);
  const [paymentErrorMessage, setPaymentErrorMessage] = useState("");
  const processPaymentResult = (result, id) => {
    /**** successfully processed transactions ****/
    const regExpSuccess = new RegExp(/^(000.000.|000.100.1|000.[36])/);
    if (regExpSuccess.test(result.code)) {
      setPaymentSuccessful(true);
      setPaymentError(false);
      setPaymentPending(false);
      setPaymentSuccessfulMessage(
        `Thanks for your payment Mr/Ms/Miss ${
          JSON.parse(sessionStorage.getItem("billing_address")).first_name
        } ${JSON.parse(sessionStorage.getItem("billing_address")).last_name}, ${
          result.description
        }`
      );
      cart.clean();
      additionalCharges.clean();
      updateReservation(id);
      return;
    }
    /**** successfully processed transactions that should be manually reviewed ******/
    const regExpSuccessWithReview = new RegExp(
      /^(000.400.0[^3]|000.400.[0-1]{2}0)/
    );
    if (regExpSuccessWithReview.test(result.code)) {
      setPaymentSuccessful(true);
      setPaymentError(false);
      setPaymentPending(false);
      setPaymentSuccessfulMessage(
        `Thanks for your payment Mr/Ms/Miss ${
          JSON.parse(sessionStorage.getItem("billing_address")).first_name
        } ${JSON.parse(sessionStorage.getItem("billing_address")).last_name}, ${
          result.description
        }`
      );
      cart.clean();
      additionalCharges.clean();
      updateReservation(id);
      return;
    }
    /**** pending transactions ******/
    const regExpPending = new RegExp(/^(000.200)/);
    if (regExpPending.test(result.code)) {
      setPaymentPending(true);
      setPaymentError(false);
      setPaymentSuccessful(false);
      setPaymentPendingMessage(
        `Your payment is pending Mr/Ms/Miss ${
          JSON.parse(sessionStorage.getItem("billing_address")).first_name
        } ${JSON.parse(sessionStorage.getItem("billing_address")).last_name}, ${
          result.description
        }`
      );
      return;
    }
    /****
     * Result codes for rejected transactions
     * Result codes for rejections by the external bank or similar payment system
     * Result codes for rejections due to communication errors
     * Result codes for rejections due to system errors
     * Result codes for rejections due to error in asynchonous workflow
     * Result codes for Soft Declines
     * Result codes for rejections due to checks by external risk systems
     * Result codes for rejections due to address validation
     * Result codes for rejections due to 3Dsecure
     * Result codes for rejections due to blacklist validation
     * Result codes for rejections due to risk validation
     * Result codes for rejections due to configuration validation
     * Result codes for rejections due to registration validation
     * Result codes for rejections due to job validation
     * Result codes for rejections due to reference validation
     * Result codes for rejections due to format validation
     * Result codes for rejections due to address validation
     * Result codes for rejections due to contact validation
     * Result codes for rejections due to account validation
     * Result codes for rejections due to amount validation
     * Result codes for rejections due to risk management
     * Chargeback related result codes
     * ***/
    const regExpRejected = new RegExp(/^(000.400.[1][0-9][1-9]|000.400.2)/);
    const regExpRejectedExternalBank = new RegExp(
      /^(800.[17]00|800.800.[123])/
    );
    const regExpRejectedComunictionError = new RegExp(
      /^(900.[1234]00|000.400.030)/
    );
    const regExpRejectedSystemError = new RegExp(
      /^(800.[56]|999.|600.1|800.800.[84])/
    );
    const regExpRejectedErrorAsyncWorkflow = new RegExp(/^(100.39[765])/);
    const regExpRejectedSoftDeclines = new RegExp(/^(300.100.100)/);
    const regExpRejectedExternalRiskSystem = new RegExp(
      /^(100.400.[0-3]|100.38|100.370.100|100.370.11)/
    );
    const regExpRejectedAddressValidation = new RegExp(/^(800.400.1)/);
    const regExpRejected3DSecure = new RegExp(/^(800.400.2|100.380.4|100.390)/);
    const regExpRejectedBlackListed = new RegExp(/^(100.100.701|800.[32])/);
    const regExpRejectedRiskValidation = new RegExp(/^(800.1[123456]0)/);
    const regExpRejectedConfigurationValidation = new RegExp(
      /^(600.[23]|500.[12]|800.121)/
    );
    const regExpRejectedRegistrationValidation = new RegExp(/^(100.[13]50)/);
    const regExpRejectedJobValidation = new RegExp(/^(100.250|100.360)/);
    const regExpRejectedReferenceValidation = new RegExp(/^(700.[1345][05]0)/);
    const regExpRejectedFormatValidation = new RegExp(
      /^(200.[123]|100.[53][07]|800.900|100.[69]00.500)/
    );
    const regExpRejectedAddressValidation2 = new RegExp(/^(100.800)/);
    const regExpRejectedContactValidation = new RegExp(/^(100.[97]00)/);
    const regExpRejectedAccountValidation = new RegExp(/^(100.100|100.2[01])/);
    const regExpRejectedAmountValidation = new RegExp(/^(100.55)/);
    const regExpRejectedRiskManagement = new RegExp(
      /^(100.380.[23]|100.380.101)/
    );
    const regExpRejectedChargebackResultCodes = new RegExp(/^(000.100.2)/);
    if (
      regExpRejected.test(result.code) ||
      regExpRejectedExternalBank.test(result.code) ||
      regExpRejectedComunictionError.test(result.code) ||
      regExpRejectedSystemError.test(result.code) ||
      regExpRejectedErrorAsyncWorkflow.test(result.code) ||
      regExpRejectedSoftDeclines.test(result.code) ||
      regExpRejectedExternalRiskSystem.test(result.code) ||
      regExpRejectedAddressValidation.test(result.code) ||
      regExpRejected3DSecure.test(result.code) ||
      regExpRejectedBlackListed.test(result.code) ||
      regExpRejectedRiskValidation.test(result.code) ||
      regExpRejectedConfigurationValidation.test(result.code) ||
      regExpRejectedRegistrationValidation.test(result.code) ||
      regExpRejectedJobValidation.test(result.code) ||
      regExpRejectedReferenceValidation.test(result.code) ||
      regExpRejectedFormatValidation.test(result.code) ||
      regExpRejectedAddressValidation2.test(result.code) ||
      regExpRejectedContactValidation.test(result.code) ||
      regExpRejectedAccountValidation.test(result.code) ||
      regExpRejectedAmountValidation.test(result.code) ||
      regExpRejectedRiskManagement.test(result.code) ||
      regExpRejectedChargebackResultCodes.test(result.code)
    ) {
      setPaymentError(true);
      setPaymentSuccessful(false);
      setPaymentPending(false);
      setPaymentErrorMessage(result.description);
      return;
    }
  };
  const updatePaymentTransactions = (idCheckout, status_code, description) => {
    const postData = new FormData();
    postData.append("status_code", status_code);
    postData.append("description", description);
    api
      .post("response-url/" + idCheckout, postData)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const updateReservation = (id) => {
    //block the car
    const carId = sessionStorage.getItem("car_id");
    const tmpFilter = JSON.parse(sessionStorage.getItem("filter"));
    const postData = {
      start_date_date: tmpFilter.datePickup.split(" ")[0],
      start_date_time: tmpFilter.datePickup.split(" ")[1],
      end_date_date: tmpFilter.dateReturn.split(" ")[0],
      end_date_time: tmpFilter.dateReturn.split(" ")[1],
    };
    race_api
      .post(
        process.env.REACT_APP_API_FLEETS_VEHICLE_BLOCKED_PERIODS.replace(
          "[id]",
          carId
        ),
        postData
      )
      .then(() => {
        console.log("car blocked successfully!");
      })
      .catch(() => {
        console.err("error blocking the car");
      });
    //create payment in the Rent API
    const paymentData = {
      field_29: "payment",
      field_31: "Credit Card", //payment type
      field_32: {
        day: parseInt(Moment(new Date()).format("DD")),
        month: parseInt(Moment(new Date()).format("MM")),
        year: parseInt(Moment(new Date()).format("YYYY")),
      }, //date of the payment
      field_34: cart.total, //amount
      field_35: `Payment done; ID: ${id}`, //reference
      field_37: "Approved", //payment status
    };
    race_api
      .post(
        process.env.REACT_APP_API_CAR_RENTAL_RESERVATION_PAYMENT.replace(
          "[resevation_id]",
          cart.idReservation
        ),
        paymentData
      )
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  useEffect(() => {
    //get the status payment
    const params = new URLSearchParams(window.location.search);
    const resourcePath = params.get("resourcePath");
    const data = new URLSearchParams();
    data.append("entityId", process.env.REACT_APP_3DSECURE_PRODUCTION);
    paymentApi
      .get(resourcePath, { params: data })
      .then((res) => {
        processPaymentResult(res.data.result, res.data.id);
        setIdCheckout(res.data.ndc);
        updatePaymentTransactions(
          res.data.ndc,
          res.data.result.code,
          res.data.result.description
        );
      })
      .catch((err) => {
        setPaymentError(true);
        setPaymentSuccessful(false);
        setPaymentPending(false);
        setPaymentErrorMessage(
          "There was an error with the payment, please try again, if the problem persists consult the administrator"
        );
      });
  }, []);
  return (
    <section className="content max-w-xl mx-auto overflow-hidden md:max-w-2xl lg:max-w-4xl xl:max-w-6xl p-4">
      <Breadcrumb />
      <h2 className="text-red-600 text-2xl text-center mb-4">
        <span className="text-black text-lg">Payment Response</span>
      </h2>
      {paymentSuccessful && (
        <p className="success text-green-600 bg-green-200 rounded-sm p-2 mt-1 mb-1 text-sm">
          {paymentSuccessfulMessage}
        </p>
      )}
      {paymentPending && (
        <p className="pending text-yellow-600 bg-yellow-200 rounded-sm p-2 mt-1 mb-1 text-sm">
          {paymentPendingMessage}
        </p>
      )}
      {paymentError && (
        <>
          <p className="error text-red-600 bg-red-200 rounded-sm p-2 mt-1 mb-1 text-sm">
            {paymentErrorMessage}
          </p>
          <Link
            to="/placeorder"
            className="bg-red-600 text-white py-1 px-2 rounded-sm mt-2 block w-24 text-center"
          >
            Try again
          </Link>
        </>
      )}
    </section>
  );
};

export default Response;
