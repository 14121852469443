import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaPen, FaTrash } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import api from "../../../service/race_api";
import summaryIcon from "../../../assets/images/cart-summary.svg";
import Moment from "moment";
import {
  CartContext,
  FilterContext,
  AdditionalChargesContext,
} from "../../../components/context/MainProviders.js";
import Modal from "../../../components/layout/blocks/Modal.js";

const RentalSummary = (props) => {
  const cart = useContext(CartContext);
  const filter = useContext(FilterContext);
  const additionalCharges = useContext(AdditionalChargesContext);
  const [editStartDate, setEditStartDate] = useState(false);
  const [editEndDate, setEditEndDate] = useState(false);
  const [diffDays, setDiffDays] = useState(0);
  const [total, setTotal] = useState(0);
  const [tax, setTax] = useState(0);
  const [editLocationFrom, setEditLocationFrom] = useState(false);
  const [editLocationTo, setEditLocationTo] = useState(false);
  const [locations, setLocations] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [typeModal, setTypeModal] = useState("alert");
  const [modalTitle, setModalTitle] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const navigate = useNavigate();
  const [billingAddress, setBillingAddress] = useState(
    sessionStorage.getItem("billing_address") !== null
      ? JSON.parse(sessionStorage.getItem("billing_address"))
      : {
          first_name: "",
          last_name: "",
          email: "",
          phone: "",
          city: "",
          address: "",
          zip_code: "",
          country: "MU",
          birthday: "",
          hotel_room: "",
        }
  );
  const [driversLicense, setDriversLicense] = useState(
    sessionStorage.getItem("drivers_license") !== null
      ? JSON.parse(sessionStorage.getItem("drivers_license"))
      : {
          dl_number: "",
          expiration_date: "",
          file: "",
        }
  );
  const [carPrice, setCarPrice] = useState(
    sessionStorage.getItem("car_price") !== null
      ? sessionStorage.getItem("car_price")
      : 0
  );
  const activatePickup = () => {
    if (!editStartDate) {
      setEditStartDate(true);
      setEditLocationFrom(true);
      setShowModal(false);
    } else {
      setEditStartDate(false);
      setEditLocationFrom(false);
    }
  };
  const activateReturn = () => {
    if (!editEndDate) {
      setEditEndDate(true);
      setEditLocationTo(true);
      setShowModal(false);
    } else {
      setEditEndDate(false);
      setEditLocationTo(false);
    }
  };
  const calculateDiffDays = () => {
    let calculateDiffDays = 0;
    if (filter.dateReturn !== null && filter.datePickup !== null) {
      let diffTime = filter.dateReturn.getTime() - filter.datePickup.getTime();
      calculateDiffDays = Math.ceil(diffTime / (1000 * 3600 * 24));
      setDiffDays(calculateDiffDays);
    }
    return calculateDiffDays;
  };
  const getServiceAmount = (price, id_service, diff_days) => {
    let amount = 0;
    let charge_type = "";
    let tmpSelectedAdditionalCharges = JSON.parse(
      sessionStorage.getItem("additional_charges")
    ).selectedItems;
    tmpSelectedAdditionalCharges.forEach((category) => {
      category.list.forEach((item) => {
        if (
          item.id_service === id_service &&
          item.value !== "0" &&
          item.value !== false
        ) {
          if (item.value === true) {
            amount = price;
          } else {
            amount = price * item.value;
          }
          charge_type = item.charge_type;
        }
      });
    });
    if (charge_type === "amount") return Math.ceil(amount);
    return Math.ceil(amount * diff_days);
  };
  const calculateTotal = () => {
    let total = 0;
    let calculateDiffDays = 0;
    let tmpAdditionalCharges = JSON.parse(
      sessionStorage.getItem("additional_charges")
    );

    if (filter.dateReturn !== null && filter.datePickup !== null) {
      let diffTime = filter.dateReturn.getTime() - filter.datePickup.getTime();
      calculateDiffDays = diffTime / (1000 * 3600 * 24);
    }
    if (tmpAdditionalCharges !== null) {
      let tmpSelectedAdditionalCharges = tmpAdditionalCharges.selectedItems;
      tmpSelectedAdditionalCharges.forEach((category) => {
        category.list.forEach((item) => {
          if (item.value !== "0" && item.value !== false) {
            if (item.charge_type === "amount") total = total + +item.amount;
            else total += item.amount * Math.ceil(calculateDiffDays);
          }
        });
      });
    }

    let total_car_price =
      sessionStorage.getItem("car_price") * Math.ceil(calculateDiffDays);

    total = total + total_car_price;

    //calculate the tax
    if (process.env.REACT_APP_TAX_ENABLED === "true") {
      let tax = (total_car_price * process.env.REACT_APP_TAX) / 100;
      setTax(tax);

      total = total + tax;
    }
    setTotal(total);

    //insert the total amount to the cart
    cart.changeTotal(total);
  };
  const validateCart = () => {
    let diffDays = calculateDiffDays();
    //validate the days
    if (diffDays < 1 && diffDays >= 0) {
      setTypeModal("alert");
      setModalTitle("Missing information");
      setModalMessage(process.env.REACT_APP_MESSAGE_3);
      setShowModal(true);
      return;
    }
    if (diffDays < 0) {
      setTypeModal("alert");
      setModalTitle("Error");
      setModalMessage(process.env.REACT_APP_MESSAGE_4);
      setShowModal(true);
      return;
    }
    //print all the required fields not completed
    if (window.location.pathname === "/billing" && !validateBilling()) {
      setTypeModal("alert");
      setModalTitle("Billing Address Required Information");
      setModalMessage(process.env.REACT_APP_MESSAGE_5);
      setShowModal(true);
      activateErrorFields();
      return;
    }
    //validate expiration date (more 1 year)
    if (window.location.pathname === "/billing" && !validateExpirationDate()) {
      setTypeModal("alert");
      setModalTitle("Error");
      setModalMessage(process.env.REACT_APP_MESSAGE_6);
      setShowModal(true);
      activateErrorField("expiration-date", "Field is not valid!");
      return;
    }
    //validate car's rate
    if (
      sessionStorage.getItem("car_price") === "0" ||
      sessionStorage.getItem("car_price") === 0 ||
      sessionStorage.getItem("car_price") === null
    ) {
      setTypeModal("alert");
      setModalTitle("Error");
      setModalMessage(process.env.REACT_APP_MESSAGE_7);
      setShowModal(true);
      return;
    }
    if (props.step === "review") createUpdateCustomer();
    navigate("/" + props.step);
  };
  const activateErrorFields = () => {
    const billingAddress = JSON.parse(
      sessionStorage.getItem("billing_address")
    );
    if (billingAddress.first_name === "") activateErrorField("first-name");
    if (billingAddress.last_name === "") activateErrorField("last-name");
    if (billingAddress.email === "") activateErrorField("email");
    if (billingAddress.address === "") activateErrorField("address");
    if (billingAddress.phone === "") activateErrorField("phone");
    if (billingAddress.city === "") activateErrorField("city");
  };
  const activateErrorField = (
    field_name,
    message = "This field is required!"
  ) => {
    const field = document.getElementById("field-" + field_name);
    field.querySelector("small.error").classList.remove("hidden");
    field.querySelector("small.error").innerHTML = message;
    field.classList.add("error");
  };
  const validateBilling = () => {
    const billingAddress = JSON.parse(
      sessionStorage.getItem("billing_address")
    );
    if (Object.keys(billingAddress).length > 0) {
      //validate if all the fields are completed
      if (
        billingAddress.first_name !== "" &&
        billingAddress.last_name !== "" &&
        billingAddress.email !== "" &&
        billingAddress.country !== "" &&
        billingAddress.city !== "" &&
        billingAddress.address !== "" &&
        billingAddress.phone !== ""
      ) {
        return true;
      }
    }
    return false;
  };
  const validateExpirationDate = () => {
    const driversLicense = JSON.parse(
      sessionStorage.getItem("drivers_license")
    );
    const expirationDate = Moment(driversLicense.expiration_date);
    const currentDate = Moment(new Date());
    const duration = Moment.duration(expirationDate.diff(currentDate));
    const days = duration.asDays();
    if (days < 365) return false;
    return true;
  };
  const validateTermsAndConditions = () => {
    if (filter.datePickup === null || filter.dateReturn === null) {
      document.getElementById("modal").classList.remove("hidden");
      setTypeModal("alert");
      setModalTitle("There is missing information");
      setModalMessage(process.env.REACT_APP_MESSAGE_1);
      setShowModal(true);
    } else if (cart.acceptedTermsAndConditions === false) {
      document.getElementById("modal").classList.remove("hidden");
      setTypeModal("alert");
      setModalTitle("There is missing information");
      setModalMessage(process.env.REACT_APP_MESSAGE_2);
      setShowModal(true);
    } else {
      createUpdateReservation();
      setTimeout(() => {
        navigate("/" + props.step);
      }, 3000);
    }
  };
  const triggerSummary = () => {
    const summary = document.getElementById("rental-summary");
    if (summary.classList.contains("closed")) {
      summary.classList.remove("-right-[300px]");
      summary.classList.remove("closed");
      summary.classList.add("right-0");
    } else {
      summary.classList.add("-right-[300px]");
      summary.classList.remove("right-0");
      summary.classList.add("closed");
    }
  };
  const deleteReservation = () => {
    if (cart.idReservation !== 0) {
      try {
        api
          .post(
            process.env.REACT_APP_API_CAR_RENTAL_RESERVATION_CANCEL.replace(
              "[reservation_id]",
              cart.idReservation
            )
          )
          .then((res) => {
            if (res.status === 200) {
              cart.clean();
              additionalCharges.clean();
              navigate("/cars");
            }
          });
      } catch {
        console.error("unknown error");
      }
    } else {
      cart.clean();
      additionalCharges.clean();
      navigate("/cars");
    }
  };
  const changeDates = (type, date) => {
    if (type === "pickup") {
      filter.ChangeDatePickup(date);
    }
    if (type === "return") {
      filter.ChangeDateReturn(date);
    }
    props.initCarInformation();
  };
  const createUpdateCustomer = async () => {
    const tmpBillingAddres = JSON.parse(
      sessionStorage.getItem("billing_address")
    );
    const tmpDriversLicense = driversLicense;
    const tmpFilter = JSON.parse(sessionStorage.getItem("filter"));

    //create or update customer
    const postDataCustomer = {
      pick_up_date: tmpFilter.datePickup.split(" ")[0],
      pick_up_time: tmpFilter.datePickup.split(" ")[1],
      pick_up_location: tmpFilter.from,
      return_date: tmpFilter.dateReturn.split(" ")[0],
      return_time: tmpFilter.dateReturn.split(" ")[1],
      return_location: tmpFilter.to,
      brand_id: tmpFilter.brandId,
      vehicle_class_id: tmpFilter.classId,
      contact_entity: "person",
      field_2: tmpBillingAddres.first_name,
      field_3: tmpBillingAddres.last_name,
      field_9: tmpBillingAddres.email,
      field_193: tmpBillingAddres.address,
      field_195: tmpBillingAddres.city,
      field_198: tmpBillingAddres.zip_code,
      field_62: tmpBillingAddres.country,
      field_8: tmpBillingAddres.phone,
      "field_15[day]": tmpBillingAddres.birthday.split("-")[2],
      "field_15[month]": tmpBillingAddres.birthday.split("-")[1],
      "field_15[year]": tmpBillingAddres.birthday.split("-")[2],
      field_254: tmpDriversLicense.dl_number,
      field_256: tmpDriversLicense.expiration_date,
      field_252: tmpDriversLicense.file,
    };

    if (
      tmpBillingAddres.prev_email === tmpBillingAddres.email &&
      !tmpBillingAddres.hasOwnProperty("id_customer")
    ) {
      postDataCustomer.contact_id = tmpBillingAddres.customer_id;
    }

    const customer = await api.post(
      process.env.REACT_APP_API_CAR_RENTAL_ADD_CUSTOMER,
      postDataCustomer
    );

    //update billing information with customer
    if (customer.status === 200) {
      let customer_id = customer.data.data.customer.id;
      tmpBillingAddres.customer_id = customer_id; //save the customer id in the sessionStorage
      tmpBillingAddres.prev_email = tmpBillingAddres.email; //save the same email
      sessionStorage.setItem(
        "billing_address",
        JSON.stringify(tmpBillingAddres)
      );
    }
  };
  const createUpdateReservation = async () => {
    const tmpBillingAddres = JSON.parse(
      sessionStorage.getItem("billing_address")
    );
    const customer_id = tmpBillingAddres.customer_id;
    const tmpFilter = JSON.parse(sessionStorage.getItem("filter"));

    //get the additional charges
    const additional_charges = [];
    const additionalCharges = JSON.parse(
      sessionStorage.getItem("additional_charges")
    );
    additionalCharges.selectedItems.forEach((category) => {
      category.list.forEach((item) => {
        additional_charges.push(item.id_service);
      });
    });

    if (cart.idReservation !== null) {
      //update reservation
      const postDataReservation = {
        reservation_id: cart.idReservation,
        customer_id: customer_id,
        pick_up_date: tmpFilter.datePickup.split(" ")[0],
        pick_up_time: tmpFilter.datePickup.split(" ")[1],
        pick_up_location: tmpFilter.from,
        return_date: tmpFilter.dateReturn.split(" ")[0],
        return_time: tmpFilter.dateReturn.split(" ")[1],
        return_location: tmpFilter.to,
        brand_id: filter.brandId,
        vehicle_class_id: filter.classId,
        additional_charges: additional_charges,
      };
      const updateReservation = await api.post(
        process.env.REACT_APP_API_CAR_RENTAL_RESERVATIONS_UPDATE.replace(
          "[id]",
          cart.idReservation
        ),
        postDataReservation
      );
      if (updateReservation.data.status_code === 200) {
        //save the reservation id
        cart.changeIdReservation(updateReservation.data.data.reservation.id);
      }
    } else {
      //create reservation
      const postDataReservation = {
        customer_id: customer_id,
        pick_up_date: tmpFilter.datePickup.split(" ")[0],
        pick_up_time: tmpFilter.datePickup.split(" ")[1],
        pick_up_location: tmpFilter.from,
        return_date: tmpFilter.dateReturn.split(" ")[0],
        return_time: tmpFilter.dateReturn.split(" ")[1],
        return_location: tmpFilter.to,
        brand_id: filter.brandId,
        vehicle_class_id: filter.classId,
        additional_charges: additional_charges,
      };
      const newReservation = await api.post(
        process.env.REACT_APP_API_CAR_RENTAL_RESERVATIONS_CONFIRM,
        postDataReservation
      );
      if (newReservation.data.status_code === 200) {
        //save the reservation id
        cart.changeIdReservation(newReservation.data.data.reservation.id);
      }
      const postDataAssignCarToReservation = {
        reservation_id: newReservation.data.data.reservation.id,
        vehicle_id: sessionStorage.getItem("car_id"),
      };
      let url = process.env.REACT_APP_API_CAR_RENTAL_ASSIGN_VEHICLE.replace(
        "[vehicle_id]",
        postDataAssignCarToReservation.vehicle_id
      );
      url = url.replace(
        "[reservation_id]",
        postDataAssignCarToReservation.reservation_id
      );
      //add the car to the reservation
      await api.post(url, postDataAssignCarToReservation);
    }
  };
  let observer = new IntersectionObserver(
    function (entries) {
      let summary = document.getElementById("rental-summary");
      if (entries[0].isIntersecting !== true) {
        summary.classList.add("fixed");
        summary.classList.remove("lg:relative");
        summary.classList.add("top-[95px]");
        summary.classList.add("right-[2%]");
        summary.classList.remove("lg:w-1/3");
        summary.classList.add("lg:w-1/4");
      } else {
        summary.classList.remove("fixed");
        summary.classList.add("lg:relative");
        summary.classList.remove("top-[95px]");
        summary.classList.remove("right-[2%]");
        summary.classList.remove("lg:w-1/4");
        summary.classList.add("lg:w-1/3");
      }
    },
    { threshold: [0] }
  );
  useEffect(() => {
    window.addEventListener("scroll", function () {
      //get the current page, validate if is the car's details page
      let url = window.location.pathname;
      if (window.screen.width > 1023) {
        if (url.search("car") !== -1) {
          observer.observe(document.querySelector("div#car-header"));
        } else {
          observer.observe(document.querySelector("section.navigation"));
        }
      }
    });
    //fill out the saved locations in local storage
    api.get(process.env.REACT_APP_API_FLEETS_LOCATIONS).then((res) => {
      setLocations(res.data.fleets_locations);
    });
  }, []);
  useEffect(() => {
    calculateDiffDays();
    calculateTotal();
  }, [additionalCharges, filter]);
  return (
    <div
      id="rental-summary"
      className="closed top-[30%] lg:top-unset w-[300px] -right-[300px] lg:-right-0 fixed lg:relative border border-neutral-300 lg:border-none lg:w-1/3 py-2 px-4 bg-white"
    >
      <span
        className="summary-toggle absolute w-[35px] -left-[35px] border-t border-l border-b border-neutral-300 cursor-pointer p-[3px] top-1 bg-white lg:hidden"
        onClick={triggerSummary}
      >
        <img src={summaryIcon} />
      </span>
      <h2 className="text-red-600 text-sm mb-4">Summary</h2>
      <div className="summary">
        <ul id="summary-pickup-details" className="mb-2">
          <li className="mb-4">
            <h4 className="text-xs text-neutral-500 mb-2">
              Pickup{" "}
              {props.step !== "final" ? (
                <button onClick={() => activatePickup()} className="w-3 h-3">
                  {!editStartDate ? (
                    <FaPen className="text-red-600" />
                  ) : (
                    <span className="bg-red-600 text-white px-[5px] py-[1px] rounded-xl">
                      x
                    </span>
                  )}
                </button>
              ) : null}
            </h4>
            <p
              className="text-xs text-neutral-400 font-light mb-2"
              id="read-only-pickup"
            >
              {!editStartDate ? (
                filter.datePickup !== null ? (
                  Moment(filter.datePickup).format("DD/MM/YYYY h:mm a")
                ) : (
                  "Not specified"
                )
              ) : (
                <DatePicker
                  id="edit-pickup"
                  className="text-xs text-neutral-600 font-normal w-full border border-neutral-400 p-1"
                  selected={filter.datePickup}
                  onChange={(date) => changeDates("pickup", date)}
                  timeInputLabel="Time:"
                  placeholderText="Start of journey date/time"
                  dateFormat="dd/MM/yyyy h:mm aa"
                  showTimeInput
                  minDate={new Date()}
                />
              )}
            </p>
            {editLocationFrom ? (
              <div className="form-field relative">
                <select
                  className="w-full text-xs p-1 mb-2 focus:outline-none border border-neutral-400"
                  onChange={(e) => filter.ChangeFrom(e.target.value)}
                  value={filter.from}
                >
                  <option value="">-- Select pickup --</option>
                  {locations.map((location) => (
                    <option key={location.id} value={location.id}>
                      {location.name}
                    </option>
                  ))}
                </select>
              </div>
            ) : (
              <p className="text-xs text-neutral-400 font-light">
                {locations.map((location) =>
                  location.id == filter.from ? location.name : ""
                )}
              </p>
            )}
          </li>
          <li className="mb-4">
            <h4 className="text-xs text-neutral-500 mb-2">
              Return{" "}
              {props.step !== "final" ? (
                <button onClick={() => activateReturn()} className="w-3 h-3">
                  {!editEndDate ? (
                    <FaPen className="text-red-600" />
                  ) : (
                    <span className="bg-red-600 text-white px-[5px] py-[1px] rounded-xl">
                      x
                    </span>
                  )}
                </button>
              ) : null}
            </h4>
            <p
              className="text-xs text-neutral-400 font-light mb-2"
              id="read-only-return"
            >
              {!editEndDate ? (
                filter.dateReturn !== null ? (
                  Moment(filter.dateReturn).format("DD/MM/YYYY h:mm a")
                ) : (
                  "Not specified"
                )
              ) : (
                <DatePicker
                  id="edit-return"
                  className="text-xs text-neutral-600 font-normal w-full border border-neutral-400 p-1"
                  selected={filter.dateReturn}
                  onChange={(date) => changeDates("return", date)}
                  timeInputLabel="Time:"
                  placeholderText="Start of journey date/time"
                  dateFormat="dd/MM/yyyy h:mm aa"
                  showTimeInput
                  startDate={filter.datePickup}
                  minDate={filter.datePickup}
                />
              )}
            </p>
            {editLocationTo ? (
              <div className="form-field relative">
                <select
                  className="w-full text-xs p-1 mb-2 focus:outline-none border border-neutral-400"
                  onChange={(e) => filter.ChangeTo(e.target.value)}
                  value={filter.to}
                >
                  <option value="">-- Select return --</option>
                  {locations.map((location) => (
                    <option key={location.id} value={location.id}>
                      {location.name}
                    </option>
                  ))}
                </select>
              </div>
            ) : (
              <p className="text-xs text-neutral-400 font-light">
                {locations.map((location) =>
                  location.id == filter.to ? location.name : ""
                )}
              </p>
            )}
          </li>
        </ul>
        <table className="text-xs text-neutral-600" width="100%">
          <tbody>
            <tr className="border-b border-neutral-200">
              <td colSpan="2" className="pb-2">
                {Math.ceil(diffDays)}x Day(s)
              </td>
              <td colSpan="1" className="text-right pb-2"></td>
              <td colSpan="1" className="text-right pb-2">
                {process.env.REACT_APP_CURRENCY}
                {carPrice * Math.ceil(diffDays)}
              </td>
            </tr>
            <tr>
              <td colSpan="4" className="text-right pb-2"></td>
            </tr>
            {additionalCharges.selectedItems.map((category, index) => (
              <tr key={"additional-charge-" + index}>
                <td colSpan="4" className="pb-1">
                  <h5 className="text-xs font-bold mb-2">
                    {category.category_name}
                  </h5>
                  {category.list.map((service, index2) => (
                    <div key={"service-" + index2} className="flex flex-row">
                      <div className="pb-2 w-3/4">{service.name}</div>
                      <div className="text-right pb-2 w-1/4">
                        {process.env.REACT_APP_CURRENCY}
                        {getServiceAmount(
                          service.amount,
                          service.id_service,
                          diffDays
                        )}
                      </div>
                    </div>
                  ))}
                </td>
              </tr>
            ))}
            <tr>
              <td colSpan="4" className="pb-1">
                <h5 className="text-xs font-bold">Miscellaneous</h5>
              </td>
            </tr>
            {process.env.REACT_APP_TAX_ENABLED === "true" ? (
              <tr>
                <td colSpan="3" className="pb-2">
                  Tax ({process.env.REACT_APP_TAX}%):
                </td>
                <td colSpan="1" className="text-right pb-2">
                  {process.env.REACT_APP_CURRENCY}
                  {tax}
                </td>
              </tr>
            ) : null}
            <tr>
              <td colSpan="1" className="pb-2 font-bold text-sm">
                Total
              </td>
              <td
                colSpan="3"
                className="text-right pb-2 font-bold text-2xl text-light"
              >
                {process.env.REACT_APP_CURRENCY}
                {total}
              </td>
            </tr>
          </tbody>
        </table>
        <div className="summary-actions flex justify-end w-full mt-4">
          {(props.step === "review" || props.step === "placeorder") && (
            <button
              className="text-white bg-neutral-500 p-2 mr-2 text-xs flex flex-wrap"
              id="delete-reservation"
              onClick={deleteReservation}
            >
              <FaTrash className="mt-[0.125rem] mr-1" />
              Delete reservation
            </button>
          )}
          {props.step !== "final" ? (
            props.step === "placeorder" ? (
              <button
                className="text-red-600 bg-white border border-red-600 p-2 px-2 text-xs confirm-reservation"
                onClick={validateTermsAndConditions}
              >
                Confirm Reservation
              </button>
            ) : !props.isBlocked ? (
              <button
                className="text-white bg-red-600 p-2 px-2 text-xs continue"
                onClick={validateCart}
              >
                Continue
              </button>
            ) : null
          ) : null}
        </div>
      </div>
      <Modal
        visible={showModal}
        type={typeModal}
        title={modalTitle}
        message={modalMessage}
      />
    </div>
  );
};

export default RentalSummary;
